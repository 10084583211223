import { Link } from "gatsby"
import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import img_1_1 from "../../images/associator-select/1-1.gif"
import img_1_2 from "../../images/associator-select/1-2.gif"
import img_1_3 from "../../images/associator-select/1-3.jpg"
import img_1_4 from "../../images/associator-select/1-4.gif"
import img_1_5 from "../../images/associator-select/1-5.gif"

import img_2_1 from "../../images/associator-select/2-1.gif"
import img_2_2 from "../../images/associator-select/2-2.jpg"
import img_2_3 from "../../images/associator-select/2-3.jpg"
import img_2_4 from "../../images/associator-select/2-4.gif"
import img_2_5 from "../../images/associator-select/2-5.gif"

import img_3_1 from "../../images/associator-select/3-1.gif"
import img_3_2 from "../../images/associator-select/3-2.gif"
import img_3_3 from "../../images/associator-select/3-3.gif"
import img_3_4 from "../../images/associator-select/3-4.jpg"
import img_3_5 from "../../images/associator-select/3-5.jpg"

import img_4_1 from "../../images/associator-select/4-1.gif"
import img_4_2 from "../../images/associator-select/4-2.gif"
import img_4_3 from "../../images/associator-select/4-3.gif"
import img_4_4 from "../../images/associator-select/4-4.gif"
import img_4_5 from "../../images/associator-select/4-5.gif"

import img_5_1 from "../../images/associator-select/5-1.gif"
import img_5_2 from "../../images/associator-select/5-2.gif"
import img_5_3 from "../../images/associator-select/5-3.gif"
import img_5_4 from "../../images/associator-select/5-4.gif"
import img_5_5 from "../../images/associator-select/5-5.gif"

const selections = [
  [
    { img: img_1_1, name: "拓洋瀝青股份有限公司" },
    { img: img_1_2, name: "鴻運瀝青化學股份有限公司" },
    { img: img_1_3 },
    {
      img: img_1_4,
      name: "弼聖實業股份有限公司",
      link: "https://taia2-backup-files.s3-ap-northeast-1.amazonaws.com/associator/bisheng/bisheng.htm",
    },
    {
      img: img_1_5,
      name: "欣道實業股份有限公司",
      link: "http://www.sindau.com.tw/",
    },
  ],
  [
    { img: img_2_1 },
    { img: img_2_2 },
    { img: img_2_3 },
    { img: img_2_4, name: "建中工程股份有限公司" },
    {
      img: img_2_5,
      name: "勇宗企業股份有限公司",
      link: "http://www.yongzong.com.tw/",
    },
  ],
  [
    { img: img_3_1 },
    { img: img_3_2, name: "開磐企業股份有限公司大洲廠" },
    {
      img: img_3_3,
      name: "祥安工業股份有限公司觀音廠",
      link: "http://sanshia.webg.tw/about.php",
    },
    { img: img_3_4 },
    { img: img_3_5 },
  ],
  [
    { img: img_4_1 },
    { img: img_4_2 },
    {
      img: img_4_3,
      name: "偉雍工業股份有限公司",
      link: "http://www.weiyong.com.tw/",
    },
    { img: img_4_4, name: "崧峰瀝青工業股份有限公司" },
    { img: img_4_5, name: "陸輝瀝青工業股份有限公司" },
  ],
  [
    {
      img: img_5_1,
      name: "路聖實業股份有限公司",
      link: "http://www.lusheng.com.tw/p1.htm",
    },
    { img: img_5_2, name: "坤穎企業有限公司" },
    { img: img_5_3 },
    { img: img_5_4, name: "蔡明高股份有限公司" },
    { img: img_5_5, name: "谷昌瀝青工業股份有限公司" },
  ],
]

const AssociatorSelect = () => (
  <Layout>
    <SEO title="Associator-Select" />
    <div className="associator-select">
      <table>
        <tbody>
          {selections.map((row, i) => (
            <tr key={`row-${i}`}>
              {row.map((col, j) => {
                if (col.name) {
                  if (col.link) {
                    return (
                      <td key={col.link}>
                        <a href={col.link} target="_blank" rel="noopener noreferrer">
                          <img alt="" src={col.img} />
                        </a>
                      </td>
                    )
                  }
                  return (
                    <td key={col.name}>
                      <Link to={`/associator/select/${i + 1}-${j + 1}`}>
                        <img alt="" src={col.img} />
                      </Link>
                    </td>
                  )
                }
                return (
                  <td key={i * j}>
                    <img alt="" src={col.img} />
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </Layout>
)

export default AssociatorSelect
